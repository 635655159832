import { Link } from "react-router-dom"
import "./style.css"

export function HomePage() {

  
  return (
    <>
      <div>

        <div
          className="h-screen w-screen  z-0 position-absolute top-0 left-0 right-0"
          id="hero"
        ></div>
        <div
          className="h-screen w-screen z-10 absolute fixed top-0 left-0 right-0"
          id="text-content"
        >
          <svg
            id="big-logo"
            width="200"
            height="200"
            viewBox="0 0 474 474"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.5 289L209.5 309.5L217.357 304.5L216 287.5C218 284.5 223.6 274.2 230 257C226.5 235 232 238.5 236 234.5C239.2 231.3 255.667 238.5 263.5 242.5H270.5C270.526 258.222 270.685 267.06 269 282.5L273 283L273.25 290.75L256.5 289L220.5 302.5L217.357 304.5L209.5 309.5H186.5L182 316.5L158 332.5L102.5 320L90 332.5L83.5 341V355.5L79 376.5L149.5 430L246.5 450L356 414L420 345.5L374.5 325H359.5L332.5 307L327.5 309.5L321 302.5L310 299L293.5 292.5L290 283L297 251.5V243.5L304 226.5C304.5 222.833 305.7 213.4 306.5 205C313 197 309 193.5 314 189.5C318 186.3 322.667 188.833 324.5 190.5L337 189.5L343 182.5L329.5 172V166.5L321.5 156.5V153C323.333 150.833 327.1 146.2 327.5 145C327.9 143.8 326.333 143.5 325.5 143.5L321 147.5C317.833 150.167 310.1 155.7 304.5 156.5C298.9 157.3 287.167 172.5 282 180L273 187.5H268L256.5 195C248.5 196.5 228.1 201 210.5 207C192.9 213 184.167 230.5 182 238.5C180.333 242.333 176.6 251 175 255C173.4 259 179 259.667 182 259.5L190.5 245C193.159 258.664 195.725 259.457 199.5 266C199.658 274.908 199.902 279.889 197.5 289ZM282 284H280L278.5 280L280.5 256L282.5 239.5L284.5 239L283.5 243.5L282 284Z"
              fill="white"
            />
            <path
              d="M209.5 309.5L197.5 289C199.902 279.889 199.658 274.908 199.5 266C195.725 259.457 193.159 258.664 190.5 245L182 259.5C179 259.667 173.4 259 175 255C176.6 251 180.333 242.333 182 238.5C184.167 230.5 192.9 213 210.5 207C228.1 201 248.5 196.5 256.5 195L268 187.5H273L282 180C287.167 172.5 298.9 157.3 304.5 156.5C310.1 155.7 317.833 150.167 321 147.5L325.5 143.5C326.333 143.5 327.9 143.8 327.5 145C327.1 146.2 323.333 150.833 321.5 153V156.5L329.5 166.5V172L343 182.5L337 189.5L324.5 190.5C322.667 188.833 318 186.3 314 189.5C309 193.5 313 197 306.5 205C305.7 213.4 304.5 222.833 304 226.5L297 243.5V251.5L290 283L293.5 292.5L310 299L321 302.5L327.5 309.5L332.5 307L359.5 325H374.5L420 345.5L356 414L246.5 450L149.5 430L79 376.5L83.5 355.5V341L90 332.5L102.5 320L158 332.5L182 316.5L186.5 309.5H209.5ZM209.5 309.5L217.357 304.5M290 292.5L273.25 290.75M273.25 290.75L256.5 289L220.5 302.5L217.357 304.5M273.25 290.75L273 283L269 282.5C270.685 267.06 270.526 258.222 270.5 242.5H263.5C255.667 238.5 239.2 231.3 236 234.5C232 238.5 226.5 235 230 257C223.6 274.2 218 284.5 216 287.5L217.357 304.5M280 284H282L283.5 243.5L284.5 239L282.5 239.5L280.5 256L278.5 280L280 284Z"
              stroke="white"
            />
            <circle cx="237" cy="237" r="221" stroke="white" strokeWidth="32" />
          </svg>
          <h1 id="big-company-name" className="text-center">
            {" "}
            Alpha Marketing
          </h1>
        </div>

        <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                We Manage Your Web Presence Through Social Media Marketing
              </h1>
              {/* <!-- <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"> */}
              {/* Increase your web presence</p> --> */}
            </div>
            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <i className="fa-brands fa-facebook-f fa-xl text-sky-500 mx-2"></i>
                  <span className="title-font font-medium">Facebook</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <i className="fa-brands fa-instagram fa-xl mx-2"></i>
                  <span className="title-font font-medium">Instagram</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <i className="fa-brands fa-tiktok fa-xl mx-2"></i>
                  <span className="title-font font-medium">Tik Toc</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <i className="fa-brands fa-twitter fa-xl text-blue-400 mx-2"></i>
                  <span className="title-font font-medium">Twitter</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <i className="fa-brands fa-youtube fa-xl text-rose-500 mx-2"></i>
                  <span className="title-font font-medium">Youtube </span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <i className="fa-brands fa-pinterest fa-xl text-rose-600 mx-2"></i>
                  <span className="title-font font-medium">Pinterest</span>
                </div>
              </div>
            </div>
            {/* <!-- <button */}
            {/* className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button> --> */}
          </div>
        </section>

        <section className="text-gray-600 body-font bg-gray-100">
          <div className="container px-5 py-24 mx-auto">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-20">
              Did You Know A Social Media Web Presence Can...
              {/* <!-- <br className="hidden sm:block">Selfies Wayfarers --> */}
            </h1>
            <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
              <div className="p-4 md:w-1/3 flex">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                  <i className="fa-solid fa-earth-americas fa-2xl"></i>
                </div>
                <div className="flex-grow pl-6">
                  <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                    Bring your brand to billions of people
                  </h2>
                  <p className="leading-relaxed text-base">
                    There are 4.2 billion active social media users.
                  </p>
                  <Link
                    to="/"
                    className="mt-3 text-indigo-500 inline-flex items-center"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="p-4 md:w-1/3 flex">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                  <i className="fa-solid fa-lightbulb fa-xl"></i>
                </div>
                <div className="flex-grow pl-6">
                  <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                    Increase brand awareness
                  </h2>
                  <p className="leading-relaxed text-base">
                    82% of instagram users discover new products on the
                    platform.
                  </p>
                  <Link
                    to="/"
                    className="mt-3 text-indigo-500 inline-flex items-center"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="p-4 md:w-1/3 flex">
                <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                  <i className="fa-solid fa-handshake fa-xl"></i>
                </div>
                <div className="flex-grow pl-6">
                  <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                    Create meaningful connections
                  </h2>
                  <p className="leading-relaxed text-base">
                    Meaningful connections create trust with your customers.
                  </p>
                  <Link
                    to="/"
                    className="mt-3 text-indigo-500 inline-flex items-center"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- pricing --> */}
        <section
          className="text-gray-600 body-font overflow-hidden"
          id="pricing"
        >
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-col text-center w-full mb-20">
              <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
                Pricing
              </h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
                Whatever Your needs are. We have your back
              </p>
              <div className="flex mx-auto border-2 border-indigo-500 rounded overflow-hidden mt-6">
                <button className="py-1 px-4 bg-indigo-500 text-white focus:outline-none">
                  Monthly
                </button>
                <button className="py-1 px-4 focus:outline-none">
                  Annually
                </button>
              </div>
            </div>
            <div className="flex flex-wrap -m-4">
              {/* <!-- <div className="p-4 xl:w-1/3 md:w-1/2 w-full">
              <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                  START
                </h2>
                <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
                  Free
                </h1>
                <p className="flex items-center text-gray-600 mb-2">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  Vexillologist pitchfork
                </p>
                <p className="flex items-center text-gray-600 mb-2">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  Tumeric plaid portland
                </p>
                <p className="flex items-center text-gray-600 mb-6">
                  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                  Mixtape chillwave tumeric
                </p>
                <button className="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">
                  Button
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-auto"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
                <p className="text-xs text-gray-500 mt-3">
                  Literally you probably haven't heard of them jean shorts.
                </p>
              </div>
              </div> --> */}
              <div className="p-4 xl:w-1/3 md:w-1/2 w-full">
                <div className="h-full p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                  <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                    POPULAR
                  </span>
                  <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    Tier 1
                  </h2>
                  <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                    <span>$1000</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">
                      /mo
                    </span>
                  </h1>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>{" "}
                    3 social media platforms
                  </p>
                  <button className="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">
                    Button
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-auto"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                  <p className="text-xs text-gray-500 mt-3">
                    Literally you probably haven't heard of them jean shorts.
                  </p>
                </div>
              </div>
              <div className="p-4 xl:w-1/3 md:w-1/2 w-full">
                <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                  <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    BUSINESS
                  </h2>
                  <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                    <span>$2000</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">
                      /mo
                    </span>
                  </h1>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    6 social media platforms
                  </p>

                  <button className="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">
                    Button
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-auto"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                  <p className="text-xs text-gray-500 mt-3">
                    Literally you probably haven't heard of them jean shorts.
                  </p>
                </div>
              </div>
              <div className="p-4 xl:w-1/3 md:w-1/2 w-full">
                <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                  <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    SPECIAL
                  </h2>
                  <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                    <span>$2500</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">
                      /mo
                    </span>
                  </h1>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    6 social media platforms
                  </p>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Website
                  </p>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Google My Business
                  </p>
                  <button className="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">
                    Button
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-auto"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                  <p className="text-xs text-gray-500 mt-3">
                    Literally you probably haven't heard of them jean shorts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
