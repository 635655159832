import React from "react"

import { BrowserRouter, Route, Routes } from "react-router-dom"
// import { RecoilRoot } from "recoil"
import DesktopNav from "./components/DesktopNav"
import Footer from "./components/Footer"
import Login from "./components/Login"
import { RequireAuth } from "./components/RequireAuth"
import Dashboard from "./pages/Dashboard"
import { HomePage } from "./pages/Home"
import PrivacyPolicy from "./pages/Privacy Policy"
import TermsOfService from "./pages/TermsOfService"
import { AuthProvider } from "./contexts/isAuth"

const App = () => (
  <AuthProvider>
    {/* <RecoilRoot> */}
      <BrowserRouter>
        <DesktopNav></DesktopNav>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    {/* </RecoilRoot> */}
  </AuthProvider>
)

export default App
