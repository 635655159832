import { signInWithEmailAndPassword } from "firebase/auth"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { auth } from "../utils/firebase"
// import {useRecoilState} from "recoil"
// import isAuthAtom from "../state/atoms"
const Login = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    error: false,
    loading: false,
  })

  const history = useNavigate()

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setState({ ...state, [name]: value })
  }

  const handleSubmit =  async (e) => {
    e.preventDefault()
    const { email, password } = state

    setState({...state,error:false,loading:true})
    if (email !== "" && password !== "") {
        try {
            // const user =  await signInWithEmailAndPassword(auth, email, password)
            await signInWithEmailAndPassword(auth, email, password)
            // setUser(user)
            setState({...state,loading:false})
            history("/dashboard")
        } catch (error) {
            setState({...state,error:true,loading:false})
        }
    }
  }

  return (
    <section id="login-background" className="bg-blueGray-50 pt-40 pb-32">
      <div className="w-full lg:w-4/12 px-4 mx-auto pt-6">
        <div className=" bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div className="rounded-t mb-0 px-6 py-6">
            <svg
              className="mx-auto"
              width="150"
              height="150"
              viewBox="0 0 474 474"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M197.5 289L209.5 309.5L217.357 304.5L216 287.5C218 284.5 223.6 274.2 230 257C226.5 235 232 238.5 236 234.5C239.2 231.3 255.667 238.5 263.5 242.5H270.5C270.526 258.222 270.685 267.06 269 282.5L273 283L273.25 290.75L256.5 289L220.5 302.5L217.357 304.5L209.5 309.5H186.5L182 316.5L158 332.5L102.5 320L90 332.5L83.5 341V355.5L79 376.5L149.5 430L246.5 450L356 414L420 345.5L374.5 325H359.5L332.5 307L327.5 309.5L321 302.5L310 299L293.5 292.5L290 283L297 251.5V243.5L304 226.5C304.5 222.833 305.7 213.4 306.5 205C313 197 309 193.5 314 189.5C318 186.3 322.667 188.833 324.5 190.5L337 189.5L343 182.5L329.5 172V166.5L321.5 156.5V153C323.333 150.833 327.1 146.2 327.5 145C327.9 143.8 326.333 143.5 325.5 143.5L321 147.5C317.833 150.167 310.1 155.7 304.5 156.5C298.9 157.3 287.167 172.5 282 180L273 187.5H268L256.5 195C248.5 196.5 228.1 201 210.5 207C192.9 213 184.167 230.5 182 238.5C180.333 242.333 176.6 251 175 255C173.4 259 179 259.667 182 259.5L190.5 245C193.159 258.664 195.725 259.457 199.5 266C199.658 274.908 199.902 279.889 197.5 289ZM282 284H280L278.5 280L280.5 256L282.5 239.5L284.5 239L283.5 243.5L282 284Z"
                fill="black"
              />
              <path
                d="M209.5 309.5L197.5 289C199.902 279.889 199.658 274.908 199.5 266C195.725 259.457 193.159 258.664 190.5 245L182 259.5C179 259.667 173.4 259 175 255C176.6 251 180.333 242.333 182 238.5C184.167 230.5 192.9 213 210.5 207C228.1 201 248.5 196.5 256.5 195L268 187.5H273L282 180C287.167 172.5 298.9 157.3 304.5 156.5C310.1 155.7 317.833 150.167 321 147.5L325.5 143.5C326.333 143.5 327.9 143.8 327.5 145C327.1 146.2 323.333 150.833 321.5 153V156.5L329.5 166.5V172L343 182.5L337 189.5L324.5 190.5C322.667 188.833 318 186.3 314 189.5C309 193.5 313 197 306.5 205C305.7 213.4 304.5 222.833 304 226.5L297 243.5V251.5L290 283L293.5 292.5L310 299L321 302.5L327.5 309.5L332.5 307L359.5 325H374.5L420 345.5L356 414L246.5 450L149.5 430L79 376.5L83.5 355.5V341L90 332.5L102.5 320L158 332.5L182 316.5L186.5 309.5H209.5ZM209.5 309.5L217.357 304.5M290 292.5L273.25 290.75M273.25 290.75L256.5 289L220.5 302.5L217.357 304.5M273.25 290.75L273 283L269 282.5C270.685 267.06 270.526 258.222 270.5 242.5H263.5C255.667 238.5 239.2 231.3 236 234.5C232 238.5 226.5 235 230 257C223.6 274.2 218 284.5 216 287.5L217.357 304.5M280 284H282L283.5 243.5L284.5 239L282.5 239.5L280.5 256L278.5 280L280 284Z"
                stroke="black"
              />
              <circle
                cx="237"
                cy="237"
                r="221"
                stroke="black"
                strokeWidth="32"
              />
            </svg>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="login-email"
                >
                  Email
                </label>
                <input
                  id="login-email"
                  type="email"
                  name="email"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  onChange={handleChange}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="login-password"
                >
                  Password
                </label>
                <input
                  id="login-password"
                  type="password"
                  name="password"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </div>
              <div className="text-center mt-6">
                <button
                  onClick={handleSubmit}
                  className="bg-black text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  {" "}
                  Sign In{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
