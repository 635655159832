import React /*,{ useEffect }*/ from "react"
// import { query, collection, limit, getDocs } from "firebase/firestore"
// import { firestore } from "../../utils/firebase"
import { auth } from "../../utils/firebase"
const Dashboard = () => {
  // useEffect(() => {
  //   async function init() {
  //     const q = query(collection(firestore, "cars"), limit(10))
  //     const snapshot = await getDocs(q)
  //     const docs = snapshot.docs
  //     console.log(docs)
  //   }
  //   init()
  // }, [])
  const handleClick = async () => {
    try {
      await auth.signOut()
    } catch (error) {
      alert("error signining out")
    }
  }
  return (
    <div className="pt-40">
      <button
        className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        onClick={handleClick}
      >
        signout
      </button>
    </div>
  )
}

export default Dashboard
