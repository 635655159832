// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import {getFirestore} from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFC-p--_PmIdJxUSiZXjYHxEvG7NtEH_s",
  authDomain: "marketing-website-24229.firebaseapp.com",
  projectId: "marketing-website-24229",
  storageBucket: "marketing-website-24229.appspot.com",
  messagingSenderId: "732153337982",
  appId: "1:732153337982:web:4b9fa8cae7fc4eda1de02f",
  measurementId: "G-763D5MMKQ9",
}
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const firestore = getFirestore(app)
