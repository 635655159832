//https://stackoverflow.com/questions/37883981/cant-get-currentuser-on-load

import React, { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { AuthContext } from "../contexts/isAuth"
// import { useRecoilState } from "recoil"
// import isAuthAtom from "../state/atoms"

export function RequireAuth({ children }) {

  // const [user] = useRecoilState(isAuthAtom)
  const { user } = useContext(AuthContext)
  console.log("user",user)
  const location = useLocation()

  if (!user) {

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />
  }

  // console.log(user)
  return children
}
