import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
// import { useRecoilState } from "recoil"
import { AuthContext } from "../contexts/isAuth"
// import isAuthAtom from "../state/atoms"

const Footer = () => {
  // const [user] = useRecoilState(isAuthAtom)

  const {user}  = useContext(AuthContext)
  return (
    <footer className="text-gray-600 body-font bg-slate-700">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <Link
            to="/"
            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
          >
            <svg
              width="50"
              height="50"
              viewBox="0 0 474 474"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M197.5 289L209.5 309.5L217.357 304.5L216 287.5C218 284.5 223.6 274.2 230 257C226.5 235 232 238.5 236 234.5C239.2 231.3 255.667 238.5 263.5 242.5H270.5C270.526 258.222 270.685 267.06 269 282.5L273 283L273.25 290.75L256.5 289L220.5 302.5L217.357 304.5L209.5 309.5H186.5L182 316.5L158 332.5L102.5 320L90 332.5L83.5 341V355.5L79 376.5L149.5 430L246.5 450L356 414L420 345.5L374.5 325H359.5L332.5 307L327.5 309.5L321 302.5L310 299L293.5 292.5L290 283L297 251.5V243.5L304 226.5C304.5 222.833 305.7 213.4 306.5 205C313 197 309 193.5 314 189.5C318 186.3 322.667 188.833 324.5 190.5L337 189.5L343 182.5L329.5 172V166.5L321.5 156.5V153C323.333 150.833 327.1 146.2 327.5 145C327.9 143.8 326.333 143.5 325.5 143.5L321 147.5C317.833 150.167 310.1 155.7 304.5 156.5C298.9 157.3 287.167 172.5 282 180L273 187.5H268L256.5 195C248.5 196.5 228.1 201 210.5 207C192.9 213 184.167 230.5 182 238.5C180.333 242.333 176.6 251 175 255C173.4 259 179 259.667 182 259.5L190.5 245C193.159 258.664 195.725 259.457 199.5 266C199.658 274.908 199.902 279.889 197.5 289ZM282 284H280L278.5 280L280.5 256L282.5 239.5L284.5 239L283.5 243.5L282 284Z"
                fill="white"
              />
              <path
                d="M209.5 309.5L197.5 289C199.902 279.889 199.658 274.908 199.5 266C195.725 259.457 193.159 258.664 190.5 245L182 259.5C179 259.667 173.4 259 175 255C176.6 251 180.333 242.333 182 238.5C184.167 230.5 192.9 213 210.5 207C228.1 201 248.5 196.5 256.5 195L268 187.5H273L282 180C287.167 172.5 298.9 157.3 304.5 156.5C310.1 155.7 317.833 150.167 321 147.5L325.5 143.5C326.333 143.5 327.9 143.8 327.5 145C327.1 146.2 323.333 150.833 321.5 153V156.5L329.5 166.5V172L343 182.5L337 189.5L324.5 190.5C322.667 188.833 318 186.3 314 189.5C309 193.5 313 197 306.5 205C305.7 213.4 304.5 222.833 304 226.5L297 243.5V251.5L290 283L293.5 292.5L310 299L321 302.5L327.5 309.5L332.5 307L359.5 325H374.5L420 345.5L356 414L246.5 450L149.5 430L79 376.5L83.5 355.5V341L90 332.5L102.5 320L158 332.5L182 316.5L186.5 309.5H209.5ZM209.5 309.5L217.357 304.5M290 292.5L273.25 290.75M273.25 290.75L256.5 289L220.5 302.5L217.357 304.5M273.25 290.75L273 283L269 282.5C270.685 267.06 270.526 258.222 270.5 242.5H263.5C255.667 238.5 239.2 231.3 236 234.5C232 238.5 226.5 235 230 257C223.6 274.2 218 284.5 216 287.5L217.357 304.5M280 284H282L283.5 243.5L284.5 239L282.5 239.5L280.5 256L278.5 280L280 284Z"
                stroke="white"
              />
              <circle
                cx="237"
                cy="237"
                r="221"
                stroke="white"
                strokeWidth="32"
              />
            </svg>
            <span className="ml-3 text-xl text-white">Alpha Marketing</span>
          </Link>
          <p className="mt-2 text-sm text-white">
            A social media marketing company
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">
              PAGES
            </h2>
            <nav className="list-none mb-10">
              <li>
                <HashLink to="/#hero" className="text-white hover:text-white">
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/#pricing"
                  className="text-white hover:text-white"
                >
                  Pricing
                </HashLink>
              </li>
              {user ? (
                <li>
                  <Link to="/dashboard" className="text-white hover:text-white">
                    Dashboard
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/login" className="text-white hover:text-white">
                    Log In
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-white hover:text-white"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-of-service"
                  className="text-white hover:text-white"
                >
                  Terms of Service
                </Link>
              </li>

              {/* <!-- <li>
                            <Link to="/" className="text-white hover:text-white">Third Link</Link>
                        </li>
                        <li>
                            <Link to="/" className="text-white hover:text-white">Fourth Link</Link>
                        </li> --> */}
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-sm text-center sm:text-left">
            © 2022 Alpha Marketing —
            <Link
              to="/"
              href="https://twitter.com/alphamarketing"
              rel="noopener noreferrer"
              className="text-gray-600 ml-1"
              target="_blank"
            >
              @alphamarketing
            </Link>
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <Link to="/" className="text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </Link>
            <Link to="/" className="ml-3 text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </Link>
            <Link to="/" className="ml-3 text-gray-500">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </Link>
            <Link to="/" className="ml-3 text-gray-500">
              <svg
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </Link>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
