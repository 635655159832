import React from "react"

const PrivacyPolicy = () => {
  return (
    <div className="md:p-52 pt-52">
      <h1 className="text-center text-2xl p-2 underline">
        <b>Privacy Policy</b>
      </h1>
      <p className="text-justify p-2">
        Alpha Beta Marketing takes your privacy seriously. To better protect
        your privacy "I" provide this privacy policy notice explaining the way
        your personal information is collected and used.
      </p>

      <h2 className="text-center p-2">Collection of Routine Information </h2>
      <p className="text-justify p-2">
        This "website" or "app" track basic information about their "visitors"
        or "users". This information includes, but is not limited to, IP
        addresses, "browser" or "app" details, timestamps and referring pages.
        None of this information can personally identify specific "visitors" or
        "user" to this "website" or "app". The information is tracked for
        routine administration and maintenance purposes.
      </p>
      <h2 className="text-center p-2">Cookies</h2>
      <p className="text-justify p-2">
        where necessary, this "website" or "app" uses cookies to store
        information about a visitor’s preferences and history in order to better
        serve the "visitor" or "user" and/or present the "visitor" or "user"
        with customized content.
      </p>
      {/* ## Advertisement
          and Other Third Parties Advertising partners and other third parties
          may use cookies, scripts and/or web beacons to track "visitors" or
          "user" activities on this "website" or "app" in order to display
          advertisements and other useful information. Such tracking is done
          directly by the third parties through their own servers and is subject
          to their own privacy policies. This "website" or "app" has no
          access or control over these cookies, scripts and/or web beacons that
          may be used by third parties. Learn how to [opt out of Google’s cookie
          usage](http://www.google.com/privacy_ads.html). */}
      {/* ## Links to Third
          Party Websites "We" or "I" have included links on this "website"
          or "app" for your use and reference. "We" or "I" are not
          responsible for the privacy policies on these websites. You should be
          aware that the privacy policies of these websites may differ from
          "our" or "my" own. */}
      <h2 className="text-center p-2">Security</h2>
      <p className="text-justify p-2">
        The security of your personal information is important to "us" or "me",
        but remember that no method of transmission over the Internet, or method
        of electronic storage, is 100% secure. While "we" or "I" strive to use
        commercially acceptable means to protect your personal information, "we"
        or "I" cannot guarantee its absolute security.
      </p>
      <h2 className="text-center p-2">Changes To This Privacy Policy</h2>
      <p className="text-justify p-2">
        This Privacy Policy is effective as of 9-28-2022 and will remain in
        effect except with respect to any changes in its provisions in the
        future, which will be in effect immediately after being posted on this
        page. "We" or "I" reserve the right to update or change "our" or "my"
        Privacy Policy at any time and you should check this Privacy Policy
        periodically. If "we" or "I" make any material changes to this Privacy
        Policy, "we" or "I" will notify you either through the email address you
        have provided "us" or "me", or by placing a prominent notice on "our" or
        "my" "website" or "app".
      </p>
      <h2 className="text-center p-2">Contact Information</h2>
      <p className="text-justify p-2">
        For any questions or concerns regarding the privacy policy, please send
        "us" or "me" an email to cameronhansen91@outlook.com
      </p>
    </div>
  )
}

export default PrivacyPolicy
