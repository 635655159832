import React, { useEffect, useState } from "react"
import {auth} from "../utils/firebase"

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
//   const [pending, setPending] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
    //   setPending(false)
    })
  }, [])

//   if (pending) {
//     return <>Loading...</>
//   }

  return (
    <AuthContext.Provider
      value={{
        user:currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
